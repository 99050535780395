<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>组织</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form :model="queryinfo" ref="queryinfoRef">
          <el-col :span="6.5">
            <el-form-item prop="queryname">
              <div style="display: inline-block" class="divSpan">名称：</div>
              <div style="display: inline-block">
                <ELselect
                  v-model="queryinfo.queryname"
                  :options="Names"
                  :optionKey="Nameskeys"
                  Splaceholder="请选择搜索的名称"
                  @clear="getOrganizeList"
                ></ELselect>
              </div>
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
        </el-col>
      </el-row>

      <el-row :gutter="30">
        <el-col :span="3">
          <el-button
            round
            type="primary"
            icon="el-icon-circle-plus"
            @click="addDialog1"
            >添加组织</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="OrganizeList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            sortable
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            sortable
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="note"
            label="说明"
            min-width="200px"
          ></el-table-column>

          <el-table-column label="用户" min-width="250px">
            <template slot-scope="scope">
              <!-- 查看按钮 -->
              <el-button
                type="primary"
                size="mini"
                @click="viewUserEditDialog(scope.row)"
                >查看</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="addUserDialog(scope.row)"
                >分配</el-button
              >
              <!-- 移除组织中用户 按钮-->
              <el-button
                type="primary"
                size="mini"
                @click="removeUserDialog(scope.row)"
                >移除</el-button
              >
            </template>
          </el-table-column>

          <!-- operation 渲染 -->
          <el-table-column label="操作" min-width="250px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="viewEditDialog(scope.row)"
                >查看</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="showEditDialog(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="removeOrganizeById(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="编辑组织信息"
      :visible.sync="editDialogVisible"
      width="30%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="名称：" prop="name">
          <el-input v-model.trim="editForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="说明：" prop="note">
          <el-input v-model.trim="editForm.note" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editOrganizeInfo">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="添加组织信息"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域  表单-->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="名称：" prop="name">
          <el-input v-model.trim="addForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="说明：" prop="note">
          <el-input v-model.trim="addForm.note" clearable></el-input>
        </el-form-item>
        <el-form-item label="父组织：" prop="parent">
          <ELselect
            v-model="addForm.parent"
            :options="Names"
            :optionKey="Nameskeys"
            Splaceholder="请选择父组织的名称"
          >
          </ELselect>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addORganize">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="查看组织"
      :visible.sync="viewDialogVisible"
      width="30%"
      @close="viewDialogClosed"
    >
      <el-form :model="viewForm" ref="viewFormRef" label-width="100px">
        <el-form-item label="ID：">
          <el-input v-model.trim="viewForm.id"></el-input>
        </el-form-item>
        <el-form-item label="名称：">
          <el-input v-model.trim="viewForm.name"></el-input>
        </el-form-item>
        <el-form-item label="说明：">
          <el-input v-model.trim="viewForm.note"></el-input>
        </el-form-item>
        <el-form-item label="父组织：">
          <el-input v-model.trim="viewForm.parent"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!--添加组织用户-->
    <el-dialog
      :close-on-click-modal="false"
      :title="ADDOrganizeUser"
      :visible.sync="addOrganizeUserDialogVisible"
      width="65%"
    >
      <el-row :gutter="30">
        <el-col :span="8">
          <div style="display: inline-block">姓名：</div>
          <div style="display: inline-block">
            <el-input
              placeholder="请输入搜索的姓名"
              v-model="OrganizeUserqueryinfo.queryname"
              clearable
            ></el-input>
          </div>
        </el-col>
        <el-col :span="2.5">
          <el-button type="primary" icon="el-icon-search" @click="seachChange1"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>

      <i class="texts">可用的用户列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="SelectedOrganizeUser"
        >添加
      </el-button>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table :data="OrganizeUserAvailList" border stripe>
          <el-table-column width="50px">
            <template slot-scope="scope">
              <div v-if="typeof scope.row.OrganizeUserExisted == 'undefined'">
                <el-checkbox
                  @change="CheckOrganizeUser(scope.row)"
                  v-model="scope.row.checkchange"
                ></el-checkbox>
              </div>
              <div v-else>
                <el-checkbox v-model="checked" disabled></el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="name" label="姓名" sortable></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            sortable
          ></el-table-column>
          <el-table-column prop="email" label="邮箱" sortable></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>

      <el-pagination
        background
        @size-change="handleOrganizeUserSizeChange"
        @current-change="handleOrganizeUserCurrentChange"
        :current-page="OrganizeUserqueryinfo.pageNum"
        :page-size="OrganizeUserqueryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="OrganizeUsertotal"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOrganizeUserDialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="addUserToOrganize">确定</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">已添加的用户列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table :data="OrganizeUseraddedList" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="email" label="邮箱"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeOrganizeUserById(scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <!-- 移除组织用户 按钮-->
    <el-dialog
      :close-on-click-modal="false"
      :title="RemoveOrganizeUser"
      :visible.sync="removeOrganizeUserDialogVisible"
      width="70%"
    >
      <i class="texts">已存在的用户列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="RemovedOrganizeUser"
        >添加
      </el-button>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="OrganizeUserremoveList"
          border
          stripe
          @selection-change="handleRemovedOrganizeUserChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="name" label="姓名" sortable></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            sortable
          ></el-table-column>
          <el-table-column prop="email" label="邮箱" sortable></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="removeOrganizeUserDialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="removeUserToOrganize">移除</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">选择要移除的用户列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="confirmRemovedOrganizeUser"
          border
          stripe
          max-height="350"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="email" label="邮箱"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="NotremoveOrganizeUserById(scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <!-- 查看组织用户-->
    <el-dialog
      :close-on-click-modal="false"
      title="查看组织用户"
      :visible.sync="viewuserDialogVisible"
      width="60%"
    >
      <el-row :gutter="30" style="display: flex">
        <el-table :data="UserviewList" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="name" label="姓名" sortable></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            sortable
          ></el-table-column>
          <el-table-column prop="email" label="邮箱" sortable></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewuserDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  OrganiztionfindPage,
  OrganizationfindNamePage,
  addOrganiztion,
  deleteOrganization,
  updateOrganization,
  getOrganizationById,
  countOrganizationByName,
  getOrganizations,
  getOrganizationUsers,
  batchInsertUser,
  batchDeleteUser
} from '@/api/systemanager/organize'
import ELselect from '@/components/myself/el_select'
import { dynamicColumnId } from '@/components/myself/dynamicCol'
import { getUserById, UserfindPage, UserfindNamePage } from '@/api/systemanager/user'
import { deleteDialog } from '@/libs/confirmDialog'

export default {
  components: { ELselect },
  data () {
    return {
      queryinfo: {
        queryname: '',
        pageNum: 1,
        pageSize: 8
      },
      Names: [],
      Nameskeys: {
        value: 'name',
        label: 'name',
        label2: ''
      },
      total: 0,
      OrganizeList: [],
      editDialogVisible: false,
      editForm: {
        name: '',
        note: ''
      },
      editFormRules: {
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 20,
            message: '长度必须在3-20个字符间',
            trigger: 'blur'
          }
        ]
      },
      addDialogVisible: false,
      addForm: {
        name: '',
        note: '',
        parent: ''
      },
      addFormRules: {
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 20,
            message: '长度必须在3-20个字符间',
            trigger: 'blur'
          }
        ]
      },
      viewDialogVisible: false,
      viewForm: {},
      // 组织用户
      viewuserDialogVisible: false,
      UserviewList: [],
      // add
      checked: true,
      ADDOrganizeUser: '',
      addOrganizeUserDialogVisible: false,
      OrganizeUserqueryinfo: {
        queryname: '',
        pageNum: 1,
        pageSize: 5
      },
      OrganizeUsertotal: 0,
      OrganizeUserAvailList: [],
      OrganizeUserIds: [],
      OrganizeId: '',
      multipleSelection: [],
      OrganizeUseraddedList: [],
      // remove
      RemoveOrganizeUser: '',
      removeOrganizeUserDialogVisible: false,
      multipleRemove: [],
      OrganizeUserremoveList: [],
      confirmRemovedOrganizeUser: []
    }
  },
  mounted () {
    this.getOrganizeList()
    this.getALLName()
  },
  methods: {
    async getALLName () {
      const res = await getOrganizations()
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.Names = res.data.data
    },
    async getOrganizeList () {
      const res = await OrganiztionfindPage(
        this.queryinfo.pageNum,
        this.queryinfo.pageSize
      )
      if (res.status !== 200) {
        return this.$message.error('获取列表失败')
      }
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.OrganizeList = res.data.data.list
      this.total = res.data.data.total

      if (this.queryinfo.queryname !== '') {
        const res = await OrganizationfindNamePage(
          this.queryinfo.queryname,
          this.queryinfo.pageNum,
          this.queryinfo.pageSize
        )
        if (res.status !== 200) {
          return this.$message.error('获取列表失败')
        }
        if (res.data.status !== 200) return this.$message.error(res.data.message)

        this.OrganizeList = res.data.data.list
        this.total = res.data.data.total
      }
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getOrganizeList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getOrganizeList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getOrganizeList()
    },
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 此处是因为parent调用自身数据，每次接口需要手动刷新数据才更新
    addDialog1 () {
      this.addDialogVisible = true
      this.getALLName()
    },
    addORganize () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const resl = await countOrganizationByName(this.addForm.name)
          if (resl.data.data > 0) { return this.$message.error('名称已存在') }
          const res = await addOrganiztion(this.addForm)
          if (res.status !== 200) { return this.$message.error('失败添加组织信息') }
          this.$message.success('成功添加组织信息')
          this.addDialogVisible = false
          this.getOrganizeList()
          this.getALLName()
        }
      })
    },
    async removeOrganizeById (rowinfo) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await deleteOrganization(rowinfo.id)
        if (res.status !== 200) return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getOrganizeList()
      }
    },
    async showEditDialog (rowinfo) {
      // 先获取原有数据，再进行修改刷新
      const res = await getOrganizationById(rowinfo.id)
      if (res.status !== 200) return this.$message.error('修改失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.editForm = res.data.data
      this.editDialogVisible = true
    },
    // 监听修改对话框的关闭事件
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    // 修改信息并提交
    editOrganizeInfo () {
      this.$refs.editFormRef.validate(async valid => {
        //   // 可以发现修改信息的请求
        if (valid) {
          const resl = await countOrganizationByName(this.editForm.name)
          if (resl.data.data > 0) { return this.$message.error('名称已存在') }
          const res = await updateOrganization({ name: this.editForm.name }, this.editForm.id)
          if (res.status !== 200) { return this.$message.error('失败的更新组织信息') }
          this.editDialogVisible = false
          this.getOrganizeList()
          this.getALLName()
          this.$message.success('成功的更新组织信息')
        }
      })
    },
    async viewEditDialog (rowinfo) {
      const res = await getOrganizationById(rowinfo.id)
      if (res.status !== 200) return this.$message.error('查看失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      this.viewForm = res.data.data
      this.viewDialogVisible = true
    },
    viewDialogClosed () {
      this.$refs.viewFormRef.resetFields()
    },

    // view 组织用户
    async viewUserEditDialog (rowinfo) {
      this.UserviewList = []
      const res = await getOrganizationUsers(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      res.data.data.forEach(async item => {
        const resl = await getUserById(item.userId)
        if (typeof resl.data.data !== 'undefined') {
          this.UserviewList.push(resl.data.data)
        }
      })
      this.viewuserDialogVisible = true
    },

    // add 组织用户
    async getOrganizeUserAvailList () {
      const res = await UserfindPage(this.OrganizeUserqueryinfo.pageNum, this.OrganizeUserqueryinfo.pageSize)
      if (res.status !== 200) return this.$message.error('查询失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      let flag = 0
      res.data.data.list.forEach(item => {
        if (this.OrganizeUserIds.toString().includes(item.id)) {
          flag = flag + 1
          this.$set(item, 'OrganizeUserExisted', true)
        }
        if (flag === 0) {
          this.$set(item, 'checkchange', false)
        }
      })
      this.OrganizeUserAvailList = res.data.data.list
      this.OrganizeUsertotal = res.data.data.total

      if (this.OrganizeUserqueryinfo.queryname !== '') {
        const resl = await UserfindNamePage(this.OrganizeUserqueryinfo.queryname, this.OrganizeUserqueryinfo.pageNum, this.OrganizeUserqueryinfo.pageSize)
        if (resl.status !== 200) return this.$message.error('获取列表失败')
        let flag = 0
        resl.data.data.list.forEach(item => {
          if (this.OrganizeUserIds.toString().includes(item.id)) {
            flag = flag + 1
            this.$set(item, 'OrganizeUserExisted', true)
          }
          if (flag === 0) {
            this.$set(item, 'checkchange', false)
          }
        })
        this.OrganizeUserAvailList = resl.data.data.list
        this.OrganizeUsertotal = resl.data.data.total
      }
    },
    seachChange1 () {
      this.OrganizeUserqueryinfo.pageNum = 1
      this.getOrganizeUserAvailList()
    },
    handleOrganizeUserSizeChange (newSize) {
      this.OrganizeUserqueryinfo.pageSize = newSize
      this.getOrganizeUserAvailList()
    },
    handleOrganizeUserCurrentChange (newPage) {
      this.OrganizeUserqueryinfo.pageNum = newPage
      this.getOrganizeUserAvailList()
    },
    async addUserDialog (rowinfo) {
      this.ADDOrganizeUser = '分配组织 ' + rowinfo.name + '用户'
      this.OrganizeId = rowinfo.id
      this.OrganizeUseraddedList = []
      this.multipleSelection = []
      this.OrganizeUserIds = []
      const res = await getOrganizationUsers(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      res.data.data.forEach(item => {
        this.OrganizeUserIds.push(item.userId)
      })
      this.getOrganizeUserAvailList()
      this.addOrganizeUserDialogVisible = true
    },
    CheckOrganizeUser (rowinfo) {
      this.multipleSelection = this.multipleSelection.concat(rowinfo)
    },
    SelectedOrganizeUser () {
      this.OrganizeUseraddedList = dynamicColumnId(this.multipleSelection)
      this.OrganizeUseraddedList = this.OrganizeUseraddedList.filter(data => { return data.checkchange === true })
    },
    removeOrganizeUserById (rowinfo) {
      this.OrganizeUseraddedList = this.OrganizeUseraddedList.filter((data) => { return data.id !== rowinfo.id })
      this.multipleSelection = this.OrganizeUseraddedList
    },
    async addUserToOrganize () {
      if (this.OrganizeUseraddedList.length === 0) return this.$message.error('请选择需要添加的组织用户')

      let addOrganizeUserIDs = []
      this.OrganizeUseraddedList.forEach(item => {
        addOrganizeUserIDs.push(item.id)
      })
      const res = await batchInsertUser(this.OrganizeId, addOrganizeUserIDs)
      if (res.status !== 200) return this.$message.error('批量添加操作失败')
      this.addOrganizeUserDialogVisible = false
      this.OrganizeUseraddedList = []
      this.multipleSelection = []
    },

    // remove
    async removeUserDialog (rowinfo) {
      this.RemoveOrganizeUser = '移除组织 ' + rowinfo.name + '用户'
      this.OrganizeId = rowinfo.id
      this.OrganizeUserremoveList = []
      this.confirmRemovedOrganizeUser = []
      this.multipleRemove = []

      const res = await getOrganizationUsers(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      res.data.data.forEach(async item => {
        const resl = await getUserById(item.userId)
        if (typeof resl.data.data !== 'undefined') {
          this.OrganizeUserremoveList.push(resl.data.data)
        }
      })
      this.removeOrganizeUserDialogVisible = true
    },
    handleRemovedOrganizeUserChange (val) {
      this.multipleRemove = val
    },
    RemovedOrganizeUser () {
      this.confirmRemovedOrganizeUser = dynamicColumnId(this.multipleRemove)
    },
    NotremoveOrganizeUserById (rowinfo) {
      this.confirmRemovedOrganizeUser = this.confirmRemovedOrganizeUser.filter((data) => { return data.id !== rowinfo.id })
      this.multipleRemove = this.confirmRemovedOrganizeUser
    },
    async removeUserToOrganize () {
      if (this.confirmRemovedOrganizeUser.length === 0) return this.$message.error('请选择需要移除的组织用户')

      const Result = await deleteDialog('确定移除?', this)
      if (Result === 'confirm') {
        let Removeids = []
        this.confirmRemovedOrganizeUser.forEach(item => {
          Removeids.push(item.id)
        })
        const res = await batchDeleteUser(this.OrganizeId, Removeids)
        if (res.status !== 200) return this.$message.error('批量移除操作失败')
        this.removeOrganizeUserDialogVisible = false
        this.confirmRemovedOrganizeUser = []
        this.multipleRemove = []

      }
    }
  }
}
</script>
<style lang="less" scoped>
.texts {
  color: blue;
  font-size: 16px;
}
.divSpan {
  font-size: 16px;
}
</style>